.seventh-section-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows:repeat(2, 1fr);
    margin: -18px 14px;

    grid-gap:10px 25px ;
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center;
    */
}

.border{
    /* border: 1px solid purple; */
}

.seventh-section-container-main-card-grid{
    border: none;
    /* background-color: red; */
    /* display: flex;
    justify-content: center; */
    /* position: absolute;
    left: 34.5%; */
    /* display: flex;
    justify-content: center; */
   
   
    /* width: 30.8%; */
    /* display: flex; */
    display: grid;
   grid-column-start: 0;
   grid-column-end: 3;
  /* justify-content: center; */
  border: 1px solid purple;
  /* align-items: center;  */


    /* display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;  */
    /* justify-content: center;
    
    /* justify-content: center; */
    /* width: 90%;
    margin-left: 109%; 
    margin-top:-20px ;  */

}

  
.seventh-section-container-main-card-section{
    
    border: 1px solid #dadada;
    
    
}

.seventh-section-container-main-card{
display: flex;
/* justify-content: center; */
/* align-items: center; */
padding: 18px;
}

.seventh-section-container-main-img{
}

.seventh-section-container-main-box-img{
    height: 130px;
    width: 100px;
    
}

.seventh-section-container-main-content{
    padding: 4px 21px;
   
}


.seventh-section-container-main-content-p{
    font-size: 16px;
    line-height: 13px;
    font-weight: bold;
    text-transform: uppercase;
    color: #194c7c;
    margin: 0 0 9px;
}

.seventh-section-container-main-content-p1{
    font-weight: bold;
    font-size: 15px ;
    line-height: 24px;
}

.seventh-section-container-main-content-p2{
    color: #000;
    opacity: 0.7;
    font-size: 14px;
}

.seventh-section-container-facebook-img-main{
    height: 37px;
    margin-right: 2px;
}

.seventh-section-container-twitter-img-main{
    height: 37px;
}

.seventh-section-container-main-content-main-icon{
    display: flex;
    align-items: center;

}

