.fifthsection-main-container{
    margin-left: 14px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100px;
    font-size: calc(1rem + 0.4vw);
    color: #343a40;
    background: #ffc107;
    padding: 4px 12px;
    font-weight: bold;
    letter-spacing: 1px;
}

.fithsection-sub-container{
    margin-left:14px;
    margin-right:14px;
    display: grid;
    grid-template-columns:repeat(2, 1fr)  ;
    grid-template-rows:repeat(1, 1fr) ;
    grid-gap: 10px;
}

.fifthsection-sub-container1{
    background-color: #0d6efd;
    padding: 1.5rem;
    margin: 2px;
    border-radius: 4px 0;
}

.fifthsection-sub-container2{
    padding: 1.5rem;
    margin: 2px;
    background-color: #0d6efd;
    border-radius: 4px 0;
  }
.fifth-section-p-sub-container{
    display: flex;
    justify-content: space-between;
    
}

.fifthsection-p{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    font-size: 1.25rem;   
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.fifthsection-p-second{
    margin-bottom: 1.5rem;
}

.fifthsection-p1{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    text-shadow: 1px 1px black;
}